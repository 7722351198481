// @ts-ignore
import {commonEnvironment} from "./common_environment";

export const environment = {
    ...commonEnvironment,
    production: true,
    API_URL: 'https://api.veritas-hr.singularity.is/v1',
    CLIENT_ID: 'testclient',
    CLIENT_SECRET: 'testpass',
};
